<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle> {{ subtitle }}</v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text style="height: auto">
      <v-container v-show="listData" class="textA">
        <v-row>
          <v-col cols="12" md="4" v-for="(data, i) in listData" v-bind:key="i">
            <v-btn
              color="primary"
              v-if="listData.length != 0"
              @click="goLink(data)"
            >
              {{ textVer + (i + 1) }}
            </v-btn>
            <!-- <v-icon v-if="eliminar" @click="deleteL(i, data)"> mdi-close</v-icon> -->
          </v-col>
          <v-col>
            <h3 color="primary" v-if="listData.length == 0 || listData == ''">
              Sin archivos cargados
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text>
      <v-container v-if="cargar" class="textA">
        <input type="file" hidden multiple ref="files" @change="listFiles" />
        <v-select
          v-model="files"
          :items="files"
          chips
          clearable
          readonly
          prepend-icon="mdi-file"
          multiple
          @click="$refs.files.click()"
          @click:prepend="$refs.files.click()"
          label="* Agregar archivos"
          append-icon
        ></v-select>
      </v-container>
    </v-card-text>
    <v-card-text v-if="guardando">
      <v-progress-circular
        :width="3"
        :size="20"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="close"> Cerrar </v-btn>
      <v-btn
        v-if="tipo != 'ver'"
        :disbaled="guardando"
        color="primary"
        text
        @click="
          tipo == 'archivosVentaTrabajo'
            ? editVentaT('archivosNecesarios')
            : tipo == 'gestionS'
            ? saveS()
            : tipo == 'gestionE'
            ? saveE()
            : editVentaT('archivosTrabajo')
        "
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";
import axios from "axios";
import { host } from "../Tools/variables";

export default {
  name: "CrudArchivos",

  props: [
    "title",
    "subtitle",
    "textVer",
    "ventaId",
    "listData",
    "cargar",
    "eliminar",
    "tipo", // subeTrabajo, archivosVentaTabajo, ver , gestionE, gestionS
    "info",
  ],

  data() {
    return {
      lFiles: [],
      files: [],
      guardando: false,
      files2: [],
      arrayEliminar: [],
      eleString: "",
    };
  },

  methods: {
    listFiles(event) {
      this.files = [];
      this.files2 = Array.prototype.slice.call(event.target.files);
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push(this.$refs.files.files[i].name);
        this.lFiles.push(this.$refs.files.files[i]);
      }
    },

    close() {
      this.$emit("toggle", "cerrar");
    },

    deleteL(a, index) {
      this.$emit("toggle", a);
      this.arrayEliminar.push(index);
      this.eleString = this.arrayEliminar.join(",");
    },

    editVentaT(a) {
      // console.log("edito la venta trabajo (archivos)");
      // console.log(a);
      // console.log(this.arrayEliminar);
      this.guardando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("ventaId", this.info.id);
          fd.append("tipoEdicion", a);
          fd.append("eliminar", this.arrayEliminar.length > 0 ? "SI" : "NO");
          fd.append("archivosEliminar", this.eleString);
          fd.append("archivos", this.files2);
          for (var i = 0; i < this.files2.length; i++) {
            let file = this.files2[i];
            // Here we create unique key 'files[i]' in our response dict
            fd.append("files[" + i + "]", file);
          }
          let headers = {
            "Content-Type": "multipart/form-data",
          };
          axios
            .post(host + "/editaArchivosTrabajo", fd, headers)
            .then((result) => {
              this.guardando = false;
              let data = result.data.text;
              if (data == "OK") {
                this.close();
              } else {
                this.$alert("Error: " + data);
              }
            });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    saveE() {
      this.guardando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "modificar");
          fd.append("gestionId", this.info.id);
          fd.append("estado", this.info["Estado de la gestión"]);
          fd.append("gestionRequerida", this.info["Gestión requerida"]);
          fd.append("notasDocente", this.info["Notas docente"]);
          fd.append("observaciones", this.info["Notas"]);
          fd.append("tipoGestion", this.info["Tipo de gestión"]);
          // fd.append('evidencias', []);
          if (this.files2.length > 0) {
            // fd.append('evidencias', this.files2);
            for (var i = 0; i < this.files2.length; i++) {
              let file = this.files2[i];
              fd.append("evidencias[" + i + "]", file);
            }
          }
          fd.append("soportes", "[]");
          axios.post(host + "/gestiones", fd).then((result) => {
            let data = result.data;
            this.guardando = false;
            if (data.text == "OK") {
              this.$alert("Archivo guardado satisfactoriamente");
              this.close();
            } else {
              this.$alert(
                "No se pudo realizar la carga del archivo, inténtelo nuevamente"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    saveS() {
      this.guardando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "modificar");
          fd.append("gestionId", this.info.id);
          fd.append("estado", this.info["Estado de la gestión"]);
          fd.append("gestionRequerida", this.info["Gestión requerida"]);
          fd.append("notasDocente", this.info["Notas docente"]);
          fd.append("observaciones", this.info["Notas"]);
          fd.append("tipoGestion", this.info["Tipo de gestión"]);
          fd.append("evidencias", []);
          if (this.files2.length > 0) {
            fd.append("soportes", this.files2);
            // for (var i = 0; i < this.files2.length; i++) {
            //     let file = this.files2[i];
            //     fd.append("files[" + i + "]", file);
            // }
          }
          // fd.append('soportes',  []);
          axios.post(host + "/gestiones", fd).then((result) => {
            let data = result.data;
            this.guardando = false;
            if (data.text == "OK") {
              this.$alert("Archivo guardado satisfactoriamente");
              this.close();
            } else {
              this.$alert(
                "No se pudo realizar la carga del archivo, inténtelo nuevamente"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    goLink(s) {
      window.open(s, "_blank");
    },
  },
};
</script>
